import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Search from '../search';
import styled from 'styled-components';
import img from '../../../images/category/electronicm.webp';
import { useDispatch, useSelector } from 'react-redux';
import { isopen } from '../../../store/theme/submenu.slice';
import { motion } from "framer-motion";
import logo from '../../../images/logo/logo.jpg';
import { useNavigate } from 'react-router-dom';

const Overlay = styled.div`
position: fixed;
left: 0;
right: 0;
bottom: 0;
top: 0;
background:rgba(0, 0, 0, 0.4);
z-index: 100;


`;

const OverSlidein = styled.div`
height: 100vh;
position: fixed;
top: 0;
left: 0;
right: 0;

`;




const Submenu = ({ onclick }) => {
    return <div className='shadow fixed bg-white header_menu_sub'>
        <div className='flex relative items-center header_submenu_caption'>
            <div className="icons_sub">
                <img src={img} alt="" /></div> <div className='text-white font-medium text-lg'  >Electronics</div>

            <div className=" flex justify-cente-r items-center cursor-pointer rounded-full absolute  icon_close_submenu" onClick={onclick}>
                <i className='fa-solid fa-close text-white'></i>
            </div>
        </div>

        <a className='p-3 border block border-b-1 cursor-pointer hover:bg-gray-200 menu_links_sm'>Laptops & Desktops</a>
        <a className='p-3 border block border-b-1 cursor-pointer hover:bg-gray-200 menu_links_sm'>Computers Accessories</a>
    </div>
}


const Index = ({ showmenu = true }) => {
    const [category, setcategory] = useState([]);
    const [subcategory, setsubcategory] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('productcategory/index').then((res) => {
            setcategory(res.data.data);
            console.log(res)
        })

        axios.get('productcategory/sub/index').then((res) => {
            setsubcategory(res.data.data)
            console.log(res)

        })
    }, [])

    const options = {

    }
    const dispatch = useDispatch();
    const submenuTheme = useSelector((state) => state.submenuSlice);

    const [opensidebar, setopensidebar] = useState(false);
    const [isSubmenu_open, setisSubmenu_open] = useState(false);
    const [selectedNestedmenu, setselectedNestedmenu] = useState({});
    const [search, setsearch] = useState(false);
    const [searchText, setsearchText] = useState('');



    const selectmenu = (id) => {
        var results = category.filter((res) => res.id == id);
        const subresults = subcategory.filter((res) => res.parentCategory == results[0].id)
        const object = {
            'category': results[0],
            'subcategory': subresults
        }

        console.log(object)

        setselectedNestedmenu(object);

        setisSubmenu_open(true);
    }


    const searchmenu = (e) => {
        setsearchText(e.target.value)
        setsearch(true)
    }



    return (
        <div className='bg-white'>
            <div className='navigation__header_desktop '>

                <React.Fragment>
                    <div className='header_full'>
                        <div className="top_header_mini">
                            <div className="mx-auto flex items-center justify-between container">
                                <div>
                                    <a href="/login" className='capitalize text-blue-600 underline' >sign in</a>
                                    <span className='mx-2'>or</span>
                                    <a href="/option_select" className='capitalize text-blue-600 underline '>register</a>

                                    <a href="" className='capitalize  ml-4 '>Daily Deals & discounts</a>
                                </div>

                                <div className='flex gap-2 items-center'>
                                    <div className='icons hover:bg-gray-400 cursor-pointer flex items-center justify-center'>
                                        <i className='fa-regular  fa-bell' style={{ fontSize: 19 }}></i>
                                    </div>
                                    <div className='icons hover:bg-gray-400 cursor-pointer flex items-center justify-center'>
                                        <i className='fa-regular  fa-message' style={{ fontSize: 19 }}></i>
                                        <div className="rounded-full mini_bag"></div>
                                    </div>
                                    <span className='ml-4'>Order</span>

                                    <a href="" className='capitalize  ml-4 '>Help & Contact</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Search logo={logo} />




                </React.Fragment>
            </div>

            <div className='mobile__nav_menu'>
                <div className='mx-auto top_header_nav'>
                    <div className='flex justify-between items-center mobile_top_nav'>
                        <div className="logo">
                            <img src={logo} />
                        </div>
                        <div className="flex gap-1 items-center items">
                            <div className=" hover:bg-gray-300 cursor-pointer rounded-md flex flex-col justify-center items-center icons__usable">
                                <i className='fa-regular fa-location-dot my__iconmade'></i>
                            </div>
                            <div onClick={() => setopensidebar(!opensidebar)} className=" hover:bg-gray-300 cursor-pointer rounded-md flex flex-col justify-center items-center icons__usable">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div
                                onClick={() => navigate('/option_select')}
                                className=" hover:bg-gray-300 cursor-pointer rounded-md flex justify-center items-center icons__usable">
                                <i className='fa-regular fa-user my__iconmade'></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-auto relative  top_header_nav_search rounded-md">
                    <div className=" border flex rounded-md  search_component">
                        <input type="text" onChange={searchmenu} placeholder='Search Anything' className='pl-2 rounded-tl-md  rounded-bl-md' />
                        <div className="flex justify-center items-center bg-blue-700 search_btn"><i className='text-white fa-regular fa-search'></i></div>
                    </div>

                    {searchText && searchmenu && <div className='mt-2  shadow-md border rounded search__dropdown_control'>

                        <div className='p-2'>
                            {/* <div className="flex items-center rounded-full cursor-pointer gap-2 mt-1 mx-auto hover:bg-gray-300  search_items_list px-1">
                                <div>category</div> <div className='font-bold '>Categorr</div>
                            </div>
                            <div className="flex items-center rounded-full cursor-pointer gap-2 mt-1 mx-auto hover:bg-gray-300  search_items_list px-1">
                                <div>category</div> <div className='font-bold '>Categorr</div>
                            </div> */}
                        </div>

                    </div>}


                </div>
            </div>

            <div
                className='bg-white'>
                <div className={opensidebar ? 'mt-5 bg-white nav_menu_split active_navbar' : 'mt-5 bg-white nav_menu_split'}  >
                    <div className='flex pt-2 items-center justify-between'>
                        <div className='pl-2'>Categories</div>

                        <div className="flex items-center gap-4 group__menu__styles">
                            <a href='/option_select' className='  font-semibold cursor-pointer' style={{ fontSize: "17px" }}>My Account</a>
                            <div className=" hover:bg-gray-400 cursor-pointer rounded-md  flex justify-center close__icon" onClick={() => setopensidebar(!opensidebar)}>
                                <i className='fa-solid fa-close my__iconmade text-blue-600'></i>
                            </div>
                        </div>
                    </div>

                    <div className='contained__width mx-auto mt-5'>
                        {category.map(({ id, name }) => (
                            <div onClick={() => selectmenu(id)} className='flex items-center  p-2  px-3 justify-between cursor-pointer menu__linked_parent'>
                                <span>{name}</span>  <i className='fa-regular fa-chevron-right'></i>
                            </div>
                        ))}


                    </div>

                </div>
            </div>
            {isSubmenu_open && <div className='sub__menu_slideder'>
                <div className='group__header_search flex items-center '>
                    <div className='flex gap-2  items-center mx-auto container_object'>
                        <div className="flex  items-center justify-center back_icon" onClick={() => {
                            setisSubmenu_open(false);
                        }}>
                            <i className='fa-regular fa-arrow-left'></i>
                        </div>
                        <div className="flex search_bar_side bg-blue-200 rounded-full">
                            <div className="back_icon flex justify-center items-center search__icon">
                                <i className='fa-regular fa-search'></i>
                            </div>
                            <input type="text" className='flex w-full outline-none border-none bg-transparent rounded-tr-full rounded-br-full pr-4' />
                        </div>

                        <div className="flex  items-center justify-center back_icon" >
                            <i className='fa-regular fa-close'></i>
                        </div>
                    </div>
                </div>
                <div className='flex-1 flex flex-col body__scroll'>
                    <div className='p-3'>
                        <h1 className=' custome_heading_1'>{selectedNestedmenu.category.name}</h1>
                    </div>

                    {selectedNestedmenu.subcategory.map(({ name }) => (

                        <a href='/products' className='px-3 border py-2 hover:border-dashed flex font__semi_meduim'>{name}</a>
                    ))}



                </div>
            </div>}
            <div className='bottom__banner'></div>


        </div>
    )
}

export default Index
