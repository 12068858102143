import React, { useState, useRef, useEffect } from 'react';
import { AppProvider, Button, DropZone, Text, Thumbnail, Icon, Spinner } from '@shopify/polaris';
import { DeleteIcon } from '@shopify/polaris-icons'; // Close icon
import '@shopify/polaris/build/esm/styles.css';

const Mainimage = () => {
    const [files, setFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [showmenu, setshowmenu] = useState(false);
    const videoRef = useRef(null);  // Reference to the video element
    const canvasRef = useRef(null); // Reference to the canvas element
    const [image, setImage] = useState(null);
    const [stream, setStream] = useState(null);
    const [camerastart, setcamerastart] = useState(false); // State to store the captured image

    const overlayClick = () => {
        setshowmenu(!showmenu)
    }



    const startCamera = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });

        try {

            setStream(stream);
            setcamerastart(true);
            setshowmenu(false);

            return stream;
        } catch (error) {
            console.error('Error accessing the camera:', error);
        }
    };

    const stopCamera = (stream) => {
        if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
        }
    };


    // Function to capture the image from the video feed
    const captureImage = () => {
        const video = document.querySelector('video');
        const canvas = document.createElement('canvas');

        if (video) {
            const context = canvas.getContext('2d');
            const width = video.videoWidth;
            const height = video.videoHeight;

            // Set canvas size to match video resolution
            canvas.width = width;
            canvas.height = height;

            // Draw the current video frame on the canvas
            context.drawImage(video, 0, 0, width, height);

            // Get the image data URL from the canvas
            const imageUrl = canvas.toDataURL('image/png');
            console.log(imageUrl)
            setImage(imageUrl);
            setcamerastart(false);
            setshowmenu(false);
            stopCamera(stream);
        }
    };


    useEffect(() => {
        if (stream) {
            const videoElement = document.querySelector('video');

            if (videoElement) {
                videoElement.srcObject = stream;
            }
        }
    }, [stream]);

    return <div>

        {image == null ?
            <div onClick={overlayClick} className=' flex items-center justify-center rounded  first__image '>
                <div>
                    <i className='fa-regular fa-camera' style={{ fontSize: 20 }}></i>
                </div>
            </div>
            :
            <div className=' flex items-center justify-center rounded  first__image '>
                <img src={image} className='w-full h-full' alt="" />
            </div>
        }

        {showmenu && <div className=' flex items-center justify-center gap-4 flex-col shadow-lg fixed  floating__camera_bars'>



            <Button className='shadow border rounded hover:bg-blue-700 hover:border-blue-700' onClick={startCamera}>Camera</Button>



            <Button className='shadow border rounded'>Upload</Button>
            <button className='bg-red-400 text-white rounded hover:bg-red-600 '>Cancel</button>
        </div>}
        {showmenu && <div className="fixed overlay" onClick={overlayClick}></div>}


        {camerastart && <div className='fixed camera_view_overlay'>
            <video autoPlay className='mb-3 h-full w-full' width="100%" />
            <button onClick={captureImage} className='absolute option__camera hover:bg-red-600 rounded-full  bg-red-500'></button>
        </div>}


    </div>
}


export default Mainimage;
