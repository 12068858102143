
import { Provider } from 'react-redux';
import store from './store/store';
import axios from 'axios';
import Layout from './layout';
import './css/all.css';
import { AppProvider } from '@shopify/polaris';
import myLogo from './images/logo/logo.png';

import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

axios.defaults.baseURL = '/server/public/api/';
//axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';

axios.interceptors.request.use((config) => {
    const token = Cookies.get('token');

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});




export const clearAllCookies = () => {
    const allCookies = Cookies.get();
    for (let cookie in allCookies) {
        Cookies.remove(cookie);
    }
};


function App() {
    return (
        <AppProvider>

            <Provider store={store}>

                <Layout />
            </Provider>
        </AppProvider>
    );


}



export default App;
