import React from 'react'

const ProductsCatalog = ({ img1 }) => {
    return (
        <div className=" product__catalog_single mobile_catalog_section">
            <div className="relative shadow rounded-lg  image__link rounded">
                
                <img src={img1} />

                <div className="absolute  bg-gray-200 px-2 rounded faint_count">
                    <span className="mr-1" style={{ fontSize: 12 }}>12</span>
                    <i className="fa-regular fa-image"></i>
                </div>
            </div>
            <div className="mt-2 product_catalog_name">Lorem ipsum dolor sit amet, consectetur adipisicing.</div>
            <div className="mt-2 font-bold">CFA 20,000</div>
            <div className="mt-2 font_location_small">Douala 3days Ago </div>
        </div>
    )
}

export default ProductsCatalog

