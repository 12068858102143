import '../../css/terms.css';

const Terms = () => {
    return <div>
        <div class="sidebar">

            <ul>
                <li><a href="#cookie_setting">Cookie Setting</a></li>
                <li><a href="#posting_adds">Posting Adds</a></li>
                <li><a href="#Policies">Policies For Online Payment</a></li>
                <li><a href="#premium_packages">Premium Packages</a></li>
                <li><a href="#premium">Premium Adds</a></li>
                <li><a href="#safety">Safety with Jabu</a></li>
                <li><a href="#Search & Browse">Search & Browse</a></li>
            </ul>
        </div>


        <div class="main-content">
            <h1>Conditions of use</h1>

            <section id="cookie_setting">
                <h2>Cookie Setting</h2>
                <h3>How can I change my Jabuh.com cookie settings?</h3>
                <p>Some essential features on Jabuh.com sites just won’t work without cookies. And having other cookies switched off can seriously affect the way you’ll be able to enjoy our services.</p>

                <p>Please check your cookie settings below and turn on any cookies you’re happy with.</p>

                <p>“Strictly necessary” cookies can’t be turned off. But Functional and Performance cookies can be turned on or off below. You can learn more about cookies and what they do on our other pages.
                    Strictly necessary cookies</p>

                <p>These cookies are essential so that you can move around the website and use its features. Without these cookies services you have asked for cannot be provided.</p>

                <h2>See list of cookies</h2>
                <h3> a. Functional cookies</h3>

                <p>These cookies allow the website to remember choices you make to give you better functionality and personal features.</p>

                <h3> b.Third Party cookies</h3>

                <p>The third party cookies includes all ad networks, social media sites like facebook, twitter and other sources which are not owned by Jabuh.com.
                </p>


                <h2>Cookie policy</h2>
                <h3>How does the Jabuh.com use cookies?</h3>

                <p>Some essential functionalities of Jabuh.com requires the support of Cookies.
                    Please check the below cookie settings and go with the one that you feel happy.
                </p>
                <h4>WHAT ARE COOKIES?</h4>
                <p> A cookie is a small text file that is sent by a website to your computer or mobile device where it is stored by your web browser. A cookie contains limited non-personal data, usually a unique identifier and the name of the site. This enables a website to recognise you as you move around the site and/or each time you revisit. Cookies are used for a wide variety of purposes such as to keep you logged in or to remember what's in your basket if you're shopping online, to remember your preferences and settings, to analyse how the site is used by you, and to serve advertising to you.

                    Cookies may be served to you by the website you are visiting (a "first party cookie") or by another organisation providing services to that website, such as an analytics company or advertising network (a "third party cookie"). They will either be stored for the duration of your visit (a "session cookie") or they will remain on your device for a fixed period, which could be months or even years, to remember you across multiple browsing sessions (a "persistent cookie")</p>

                <h4>HOW WE USE COOKIES ON OUR ONLINE SERVICES?</h4>

                <p>We use cookies and tracking technologies for a variety of reasons such as to enable us to improve your experiences with our services and to deliver advertising. In particular, we use these technologies for:

                    Strictly Necessary cookies

                    Strictly Necessary cookies "Strictly necessary" cookies can't be turned off. These cookies help in functioning our services.
                    Functional cookies

                    To remember your preferences and settings and other functionality you have requested such as remembering your subscription details for Notification alerts, Newsletter so that you do not need to subscribe each time you visit.
                    Third Party cookies

                    The other services like ad networks, social media or any such external services are rendered through our properties are not managed by us and also the cookies can’t be turned off through us. Check out below in details:
                    Advertising Purposes :

                    Some of our websites contain advertising and all advertising that is served on our websites will be clearly marked with the word "Advertisement". Cookies and tracking technologies may sometimes be used to deliver advertising and marketing messages relevant to you - a common practice across the internet and known as online behavioural advertising.
                </p>
                <h2>ANALYTICS ON OUR SERVICES :</h2>

                <p> On our websites we use Google Analytics, and other similar services, to collect statistical information about how our websites are used. They use information such as your IP address, browser type and unique identifiers stored in (first party) cookies on your device to record how you interact with our website. On some services we also use Google Demographics and Interests data gathered via the Google DoubleClick cookie. We only use this data in aggregate form and we do not merge it with any other data we hold, however it is important to note that these reports are derived from Google's (third party) DoubleClick cookie and device identifiers, which are also used for advertising.

                    These analytical services help us to know how many users we have, which parts of our sites are most popular, what browsers are used (so we can maximise compatibility), the country or region where our users are located, and the demographics and interests of our users. This enables us to better understand who is using our site and to ensure we are reaching our target demographic, and to improve and tailor our services accordingly.
                </p>
                <h3> ADVERTISING ON OUR SERVICES :</h3>


                <p>Some of our services carry targeted or behavioural advertising, as well as non-targeted advertising, which helps us to pay for the delivery of these services to you. Our advertising partners use cookies accompanying the adverts to enable them and their advertisers to monitor the effectiveness of their ads (e.g. by using statistical analysis cookies).

                    We may also use cookies and your IP address to discover general information about which pages on our sites you visit and the country, city or region in which you are located. We sometimes combine this with information provided to us by third parties, together this information enables you to be grouped with other people with similar interests and places you in a market segment, such as "travel", "autos", or . We may then display advertisements which we believe people in your market segment will find relevant. We believe this makes the advertising more interesting and useful to you, and also helps us increase the value we get out of the site from our advertisers, and therefore ultimately gives us a greater ability to invest in great content for the benefit of all users.

                    We may also allow advertisers to use their own marketing data to target advertising campaigns on this website and as a result you may see advertising based upon website content you have been viewing on another website. For example, a retailer might use information about the products you browsed on their website in order to tailor their advert to you on this website. This information is not shared with us and we do not share information with the advertiser about your browsing of our website.
                </p>
            </section>



            <section id="posting_adds">
                <h2>Posting Adds</h2>
                <p>Posting an ad on Jabuh.com  is easy and simple. Just follow these simple steps:
                    <ul>
                        <li>Click on "Post your Ad" link in the header.</li>
                        <li>Select a City & Category to post.</li>
                        <li>Based on your category selection, we will display the form, just fill the details and click on "Post".</li>
                        <li>Now check your email for our message and click on the activation link to activate the ad.</li>
                    </ul>
                    Your ad will appear within a few minutes and remain active for 60 days on Jabuh.com. We also publicize your ad on our affiliate network.

                    Note: You can skip Email Id verification by signing in with Facebook / Google+ / Jabuh.com account while posting your ad.
                    I haven't received the confirmation email. What do I do?

                    Please check your spam/bulk folder for our message and mark as "Not spam". Then find our message in your inbox and click on activation link to activate your ad.

                    If you are not able to find our message in your spam/bulk folder, please email us at info@jabuh.com or call us at +237673194656. Our representatives will help you to activate your ad.
                    I have published my ad but why I can't see it on Jabuh.com?

                    <p>Sometimes even after publishing the ad, your ad will be in pending status. This is because our system has suspected your ad as Spam. In this case, your ad will be activated after our rep checks it manually. It may take maximum 24hours.
                        If you find your ad is in pending status event after 24 hours, please email us at info@jabuh.com or call us at  +237673194656. Our representatives will help you.

                    </p>
                    <p>How long will my ad be active on Jabuh.com?</p>

                    <p>All ads on Jabuh.com will remain active for 60 days. Before the ad meets it expiry date, we will send you an email to renew it. Just click on the activation link in that mail to renew it. Otherwise ignore the mail.
                    </p>How can I post a good ad on Jabuh.com and get better responses?

                    <p>To increase the chance of getting good responses, follow these simple tips:
                    </p>

                    <ul>


                        <li>Good title - A good title will attract more people to visit your ad.
                        </li>
                        <li>Description - Describe the item/service as detailed as possible. Let the buyers know where the item is located and when you will be available.
                        </li>
                        <li> Images - Upload more images. Our study says, ads with images will get 5 times more responses.
                        </li>
                        <li> Always use right spellings in your ad to make your it more search friendly.
                        </li>
                    </ul>



                    <p>
                        You can also buy Premium plans for getting more responsesSearch & Browse
                        Managing my Ads & Responses (My Jabuh.com)
                        General


                        Help | General | FAQ - - Jabuh.com

                        Free online  Cameroon classifieds. Sell, Buy, Find - faster and easier: flats, apartments, houses, PG, jobs, IT jo...


                        Safety


                        Help | Safety | FAQ - - Jabuh.com

                        Free online  Cameroon classifieds. Sell, Buy, Find - faster and easier: flats, apartments, houses, PG, jobs, IT jo...

                        Free Classified Ads India | Free Ads Posting Classifieds Cameroon | ads Ind...

                        Free online  Cameroon classifieds. Sell, Buy, Find - faster and easier: flats, apartments, houses, PG, jobs, IT jo...


                        Contact us:
                        Email: info@jabuh.com
                    </p>
                </p>
            </section>


            <section id="Policies">
                <h2>Policies For Online Payment</h2>
                <p>
                    The Terms and Conditions contained herein shall apply to any person ("User") using the services of Melah Information Technologies Co. Ltd for making Online service payments through an online mode through payment gateway . Melah Information Technologies Co. Ltd's website i.e.  www.Jabuh.com. Each User is therefore deemed to have read and accepted these Terms and Conditions.
                </p>
                <h3>A. Privacy Policy</h3>
                <p>Melah Information Technologies Co. Ltd respects and protects the privacy of the individuals that access the information and use the services provided through them. Individually identifiable information about the User is not willfully disclosed to any third party without first receiving the User's permission, as covered in this Privacy Policy. This Privacy Policy describes Melah Information Technologies Co. Ltd's treatment of personally identifiable information that Melah Information Technologies Co. Ltd collects when User is on theMelah Information Technologies Co. Ltd's website. Melah Information Technologies Co. Ltd does not collect any unique information about the User (such as User's name, email address, age, gender etc.) except when the User specifically and knowingly provide such information on the Website. Like any business interested in offering the highest quality of service to clients,Melah Information Technologies Co. Ltd may, from time to time, send email and other communication to the User telling them about the various services, features, functionality and content offered by Melah Information Technologies Co. Ltd website or seek voluntary information from you.
                    Please be aware, however, that Melah Information Technologies Co. Ltd will release specific personal information about the User if required to do so in the following cirumstances:
                    in order to comply with any valid legal process such as a search warrant, statute, or court order, or
                    if any of User's actions onMelah Information Technologies Co. Ltd's website violate the Terms of Service or any of Melah Information Technologies Co. Ltd's guidelines for specific services, or
                    to protect or defend Melah Information Technologies Co. Ltd's legal rights or property, the Melah Information Technologies Co. Ltd site, or Melah Information Technologies Co. Ltd Users; or
                    to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the security, integrity of Melah Information Technologies Co. Ltd website/offerings.
                </p>
                <h3>B. General Terms and Conditions For Online-Payments</h3>
                <p>Once a User has accepted these Terms and Conditions, he/she may register and avail the Services by registering on Melah Information Technologies Co. Ltd's
                    Melah Information Technologies Co. Ltd's rights, obligations, undertakings shall be subject to the laws in force in Cameroon, as well as any directives/ procedures of Government of Cameroon, and nothing contained in these Terms and Conditions shall be in derogation of Melah Information Technologies Co. Ltd's right to comply with any law enforcement agencies request or requirements relating to any User's use of the website or information provided to or gathered by Melah Information Technologies Co. Ltd with respect to such use. Each User accepts and agrees that the provision of details of his/ her use of the Website to regulators or police or to any other third party in order to resolve disputes or complaints which relate to the Website shall be at the absolute discretion of Melah Information Technologies Co. Ltd.
                    If any part of these Terms and Conditions are determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth herein, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these Terms and Conditions shall continue in effect.
                    These Terms and Conditions constitute the entire agreement between the User and Melah Information Technologies Co. Ltd. These Terms and Conditions supersede all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between the User andMelah Information Technologies Co. Ltd. A printed version of these Terms and Conditions and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms and Conditions to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.
                    The entries in the books of Melah Information Technologies Co. Ltd and/or the Payment Service Providers kept in the ordinary course of business of Melah Information Technologies Co. Ltd and/or the Payment Service Providers with regard to transactions covered under these Terms and Conditions and matters therein appearing shall be binding on the User and shall be conclusive proof of the genuineness and accuracy of the transaction.
                    Refund And Cancellation : Melah Information Technologies Co. Ltd will not entertain Refund / Cancellation of the payment.
                    Refund For Charge Back Transaction : In the event there is any claim for/ of charge back by the User for any reason whatsoever, such User shall immediately approach Melah Information Technologies Co. Ltd with his/ her claim details and claim refund from Melah Information Technologies Co. Ltd alone. Such refund (if any) shall be effected only by Melah Information Technologies Co. Ltd via payment gateway or by means of a demand draft or such other means as Melah Information Technologies Co. Ltd deems appropriate. No claims for refund/ charge back shall be made by any User to the Payment Service Provider(s) and in the event such claim is made it shall not be entertained.
                    In these Terms and Conditions, the term "Charge Back" shall mean, approved and settled credit card or net banking purchase transaction(s) which are at any time refused, debited or charged back to merchant account (and shall also include similar debits to Payment Service Provider's accounts, if any) by the acquiring bank or credit card company for any reason whatsoever, together with the bank fees, penalties and other charges incidental thereto.
                    Refund for fraudulent/duplicate transaction(s): The User shall directly contact Melah Information Technologies Co. Ltd for any fraudulent transaction(s) on account of misuse of Card/ Bank details by a fraudulent individual/party and such issues shall be suitably addressed by Melah Information Technologies Co. Ltd alone in line with their policies and rules.
                    Server Slow Down/Session Timeout: In case the Website or Payment Service Provider's webpage, that is linked to the Website, is experiencing any server related issues like 'slow down' or 'failure' or 'session timeout', the User shall, before initiating the second payment,, check whether his/her Bank Account has been debited or not and accordingly resort to one of the following options:
                    In case the Bank Account appears to be debited, ensure that he/ she does not make the payment twice and immediately thereafter contact Melah Information Technologies Co. Ltd via e-mail or any other mode of contact as provided by Melah Information Technologies Co. Ltd to confirm payment.
                    In case the Bank Account is not debited, the User may initiate a fresh transaction to make payment.
                    However, the User agrees that under no circumstances the Payment Gateway Service Provider shall be held responsible for such fraudulent/duplicate transactions and hence no claims should be raised to Payment Gateway Service Provider No communication received by the Payment Service Provider(s) in this regards shall be entertained by the Payment Service Provider(s).
                </p>
                <h3>C. Limitation of Liability</h3>
                <p>Melah Information Technologies Co. Ltd has made this Service available to the User as a matter of convenience. Melah Information Technologies Co. Ltd expressly disclaims any claim or liability arising out of the provision of this Service. The User agrees and acknowledges that he/ she shall be solely responsible for his/ her conduct and thatMelah Information Technologies Co. Ltd reserves the right to terminate the rights to use of the Service immediately without giving any prior notice thereof.
                    Melah Information Technologies Co. Ltd and/or the Payment Service Providers shall not be liable for any inaccuracy, error or delay in, or omission of (a) any data, information or message, or (b) the transmission or delivery of any such data, information or message; or (c) any loss or damage arising from or occasioned by any such inaccuracy, error, delay or omission, non-performance or interruption in any such data, information or message. Under no circumstances shall the Melah Information Technologies Co. Ltd and/or the Payment Service Providers, its employees, directors, and its third party agents involved in processing, delivering or managing the Services, be liable for any direct, indirect, incidental, special or consequential damages, or any damages whatsoever, including punitive or exemplary arising out of or in any way connected with the provision of or any inadequacy or deficiency in the provision of the Services or resulting from unauthorized access or alteration of transmissions of data or arising from suspension or termination of the Services.
                    Melah Information Technologies Co. Ltd and the Payment Service Provider(s) assume no liability whatsoever for any monetary or other damage suffered by the User on account of:
                    the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Payment Gateway or Services in connection thereto; and/ or
                    any interruption or errors in the operation of the Payment Gateway.
                    The User shall indemnify and hold harmless the Payment Service Provider(s) and Melah Information Technologies Co. Ltd and their respective officers, directors, agents, and employees, from any claim or demand, or actions arising out of or in connection with the utilization of the Services.
                    The User agrees that Melah Information Technologies Co. Ltd or any of its employees will not be held liable by the User for any loss or damages arising from your use of, or reliance upon the information contained on the Website, or any failure to comply with these Terms and Conditions where such failure is due to circumstance beyond Melah Information Technologies Co. Ltd's reasonable control.
                </p>

                <h3>D. Miscellaneous Conditions :</h3>
                <p>Any waiver of any rights available to Melah Information Technologies Co. Ltd under these Terms and Conditions shall not mean that those rights are automatically waived.
                    The User agrees, understands and confirms that his/ her personal data including without limitation details relating to debit card/ credit card transmitted over the Internet may be susceptible to misuse, hacking, theft and/ or fraud and that Melah Information Technologies Co. Ltd or the Payment Service Provider(s) have no control over such matters.
                    Although all reasonable care has been taken towards guarding against unauthorized use of any information transmitted by the User, Melah Information Technologies Co. Ltd does not represent or guarantee that the use of the Services provided by/ through it will not result in theft and/or unauthorized use of data over the Internet.
                    Melah Information Technologies Co. Ltd, the Payment Service Provider(s) and its affiliates and associates shall not be liable, at any time, for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information contained on the Website.
                    The User may be required to create his/ her own User ID and Password in order to register and/ or use the Services provided by Melah Information Technologies Co. Ltd on the Website. By accepting these Terms and Conditions the User aggress that his/ her User ID and Password are very important pieces of information and it shall be the User's own responsibility to keep them secure and confidential. In furtherance hereof, the User agrees to;
                    Choose a new password, whenever required for security reasons.
                    Keep his/ her User ID & Password strictly confidential.
                    Be responsible for any transactions made by User under such User ID and Password.
                    The User is hereby informed that Melah Information Technologies Co. Ltd will never ask the User for the User's password in an unsolicited phone call or in an unsolicited email. The User is hereby required to sign out of his/ her Melah Information Technologies Co. Ltd account on the Website and close the web browser window when the transaction(s) have been completed. This is to ensure that others cannot access the User's personal information and correspondence when the User happens to share a computer with someone else or is using a computer in a public place like a library or Internet cafe.
                </p>

                <h3>E. Debit/Credit Card, Bank Account Details</h3>
                <p>The User agrees that the debit/credit card details provided by him/ her for use of the aforesaid Service(s) must be correct and accurate and that the User shall not use a debit/ credit card, that is not lawfully owned by him/ her or the use of which is not authorized by the lawful owner thereof. The User further agrees and undertakes to provide correct and valid debit/credit card details.
                    The User may pay his/ her premium fee to Melah Information Technologies Co. Ltd by using a debit/credit card or through online banking account. The User warrants, agrees and confirms that when he/ she initiates a payment transaction and/or issues an online payment instruction and provides his/ her card / bank details:
                    The User is fully and lawfully entitled to use such credit / debit card, bank account for such transactions;
                    The User is responsible to ensure that the card/ bank account details provided by him/ her are accurate;
                    The User is authorizing debit of the nominated card/ bank account for the payment of premium fee selected by such User along with the applicable Fees.
                    The User is responsible to ensure sufficient credit is available on the nominated card/ bank account at the time of making the payment to permit the payment of the dues payable or the bill(s) selected by the User inclusive of the applicable Fee.
                </p>
                <h3>F. Personal Information</h3>
                <p>The User agrees that, to the extent required or permitted by law, Melah Information Technologies Co. Ltd and/ or the Payment Service Provider(s) may also collect, use and disclose personal information in connection with security related or law enforcement investigations or in the course of cooperating with authorities or complying with legal requirements.
                    The User agrees that any communication sent by the User vide e-mail, shall imply release of information therein/ therewith to Melah Information Technologies Co. Ltd. The User agrees to be contacted via e-mail on such mails initiated by him/ her.
                    In addition to the information already in the possession of Melah Information Technologies Co. Ltd and/ or the Payment Service Provider(s), Melah Information Technologies Co. Ltd may have collected similar information from the User in the past. By entering the Website the User consents to the terms of Melah Information Technologies Co. Ltd's information privacy policy and to Melah Information Technologies Co. Ltd's continued use of previously collected information. By submitting the User's personal information to Melah Information Technologies Co. Ltd, the User will be treated as having given his/her permission for the processing of the User's personal data as set out herein.
                    The User acknowledges and agrees that his/ her information will be managed in accordance with the laws for the time in force.
                </p>
                <h3>G. Payment Gateway Disclaimer</h3>
                <p>The Service is provided in order to facilitate access to view and pay for the premium service online. Melah Information Technologies Co. Ltd or the Payment Service Provider(s) do not make any representation of any kind, express or implied, as to the operation of the Payment Gateway other than what is specified in the Website for this purpose. By accepting/ agreeing to these Terms and Conditions, the User expressly agrees that his/ her use of the aforesaid online payment Service is entirely at own risk and responsibility of the User.</p>
            </section>

            <section id="premium_packages">
                <h2>Premium Packages</h2>
                <p>Premium packages are nothing but bulk premium classifieds. Based on the number of classifieds, we are giving you the discounts upto 60%. All premium packages are valid for one year.
                    Please make use of this feature and start promoting your classifieds on  Jabuh.com
                    Please select your Category *

                    Your Email ID *
                    Security *

                    Please enter the same number what you see on the left side of the box. This is to protect the website from spam.

                    Pay now
                </p>
            </section>


            <section id="premium">
                <h2>Premium Adds</h2>

                <p>Premium ad gives you additional exposure by making your ad more prominent. There is a nominal fee for converting a normal ad to a premium ad.</p>
                <h4>How to post a premium ad?</h4>

                After activating your normal ad, we will show you the premium plans on the page. You can buy any plan based on your requirement by clicking on "Pay now" link.

                <p>
                    Now you will be redirected to the payment gateway. Fill the form, select the payment option, enter the banking details and click on "Submit" link.

                    Once the payment gateway confirms the transaction, your ad gets upgraded to a premium ad.
                    How to convert an already posted ad into premium ad?

                    Login to your click.in account, choose which ad you would like to convert into premium, select any premium plan based on your requirement by clicking on "Pay now" link

                    Now you will be redirected to the payment gateway. Fill the form, select the payment option, enter the banking details and click on "Submit" link.

                    Once the payment gateway confirms the transaction your ad gets upgraded to a premium ad.
                </p>

                <h4>What are the plans, price, benefits & duration of premium ads?
                </h4>
                <h4>149 plan:</h4>
                <p>Ad will be republished on top of the page (under non-premium block).
                    This plan will help you to keep your ad fresh every day.
                    It costs Rs. 149. Plan is valid for 30 days.</p>

                <h4>249 plan:</h4>
                <p>Ad will be displayed on top of the page (under premium block)
                    Also getting instant responses by email and SMS.
                    This plan will help you to showcase your ad to maximum users & checking responses in your mobile (sms).
                    It costs Rs. 249. Plan is valid for 30 days.</p>

                <h3>What is a Premium package?</h3>


                <h3>What is a Premium package?</h3>

                <p>Premium packages are nothing but buying bulk premium ads with some discount in price.
                    You will get upto 60% discount on premium plans. (Based on the number of ads & duration)</p>
                <h3>What are the payment options?</h3>


                <p>
                    Credit card: We accept Visa, Diners Club Card, American Express, JCB card. <br />

                    Debit card: We accept all debit cards.  <br />

                    Net banking: We accept from all banks. <br />

                    Cheque Payment: <br />
                    A crossed Account Payee Cheque should be drawn in favor of " Melah Information Technologies co ltd
                    " Account no -  . And drop it at any -------------- or send it to

                    Melah Information Technologies Co. ltd
                    Jabuh.com  department

                    Once you drop the cheque, email us at cheque@jabuh.com with the following details: <br />

                    1. Ad URL/s with Premium Plan names which have to be upgraded to premium ad. <br />
                    2. Deposited Cheque number. <br />
                    3. Amount. <br />
                    4. Your Bank name and Branch name. <br />

                    Note: Your ad will be upgraded to premium ad once your cheque is cleared and we receive the money.
                    Note: We don't accept Cash Payments & Cash Deposits.
                    How to buy Premium package?

                    We have different package plans to fulfill your requirement.
                    Click on the below link and follow these simple steps.
                    Premium packages link - www.jabuh.com /package

                    Steps:

                    Select a category & a premium plan. <br />
                    Enter your email id & security code. <br />
                    Click on "Pay now" <br />
                    Now you will be redirected to the payment gateway. Fill the form, select the payment option, enter the banking details and click on "Submit" link.


                    Once the payment gateway confirms the transaction your package will be activated. You can start upgrading your ads from "My Jabuh.com"

                    Note: We don't accept Cash Payments & Cash Deposits.
                    Posting an Ad
                    Search & Browse
                    Managing my Ads & Responses (My  Jabuh.com )
                    General
                    Safety


                    Help | Safety | FAQ - - Jabuh.com

                    Free online Cameroon  classifieds. Sell, Buy, Find - faster and easier: flats, apartments, houses, PG, jobs, IT jo...


                    Contact us:
                    Email: info@jabuh.com

                </p>
            </section>

            <section id="safety">
                <h2>Safety wuth Jabu</h2>
                <p>Please report abuse, spam and sexual content at abuse@jabuh.com

                    Also mark the ad as "Spam" by clicking on the link "Mark as Spam" available on detailed ad page.
                    Somebody has falsified my identity in a posting. I am being harassed. What do I do?

                    If someone harasses you by posting an ad or by responding to your ad, please contact us at abuse@jabuh.com immediately.

                    Send us the URL/link of the ad and a brief explanation about the problem. We will immediately take the action.

                </p>
                <p>Here are some safety tips while selling or buying a product/service</p>

                <p>    Make sure you have all the necessary product details before you make the purchase.
                    Make sure you have necessary contact details of the seller or buyer.
                    Do not transact online - never pay money online to any seller or buyer.
                    Do not share your banking information or credit card details.
                    We highly recommend that you meet the seller or buyer in person.
                    At the time of delivery of the product, check the product's condition thoroughly.

                </p>
                <p> Please report any fraudulent acts, abuse, spam and sexual content to abuse@click.in.
                    Posting an Ad

                    Help | Posting an Ad | FAQ - -  Jabuh.com

                    Free online Cameroon classifieds. Sell, Buy, Find - faster and easier: flats, apartments, houses, PG, jobs, IT jo...


                    Premium Ads
                    Search & Browse
                    Managing my Ads & Responses (My Jabuh.com  )
                    General


                    Help | General | FAQ - - Jabuh.com

                    Free online Cameroon classifieds. Sell, Buy, Find - faster and easier: flats, apartments, houses, PG, jobs, IT jo...





                    Free Classified Ads India | Free Ads Posting Classifieds India | ads Ind...

                    Free online Cameroon classifieds. Sell, Buy, Find - faster and easier: flats, apartments, houses, PG, jobs, IT jo...


                    Contact us:
                    Email: info@jabuh.com



                </p>

            </section>

            <section id="Search & Browse">
                <h2>Search & Browse</h2>
                <p>You can search in two ways.

                    Keyword Search - Enter your keyword (ex: 2 bedroom apartment in Bambili) in the text box available on the header. We will be displaying the results based on your entered keyword. You can narrow down your search results by selecting a category to see the results.
                    Advance Search - This search is available on all category pages. In this, we have specifically defined the search terms based on the category you have selected. You get perfect matching results in this search.

                </p>
                <h3>How do I reply to an ad?</h3>
                <p>To contact posters, click on the link "Send Email response" on the detailed ad page. Enter the details like your email id, phone number & message, and click on "Send". Your response will be sent to the poster.

                    You can also call the phone number if it is available in the ad.
                </p>

                <h3>How do I share an ad with my friends?</h3>
                <p>You can share any ad with your friends by clicking on "Message icon" available in detailed ad page. You can also share ads via Facebook, Twitter and G+ by clicking on the respective icons available on detailed ad page.</p>

                <h3>How do I mark an ad Favorite for future reference?</h3>
                <p>You can mark an ad Favorite for future reference by clicking on "Favorite" icon available in detailed ad page</p>

                <h3>How do I receive ads in mailbox of my interest?</h3>
                <p>On the advance search results page, you will have an option to save the search. Click on that link, enter your email id and submit.
                    Important step: Now check your email for our message and click on the activation link to receive matching ads in your inbox.
                    Posting an Ad
                </p>

            </section>


        </div>
    </div>
}

export default Terms;