import React, { useEffect, useState } from 'react'

import { RouteWrapper } from '../routes'
import Inputs from '../pages/components/Inputs'
import _removeclass from '../pages/UI_logic/removeClasslist'
import ProtectedRoutes from '../routes/ProtectedRoutes';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProductOverview from './components/overview'
import Products from '../pages/products'
import Viewpgae from '../pages/viewpage'

import Animatepage from '../pages/stores/animatepage'
import Signup from '../pages/stores/loginsteps/Signup'
import Location from '../pages/stores/loginsteps/location'
import Userportal from '../pages/stores/userportal'
import Addproducts from '../pages/stores/products/addproduct'
import UserportalOverview from '../pages/stores/products/ProductOverview'
import PrivateRoutes from '../pages/auth/protectedRoutes/ProtectedRoutes'
import Login from '../pages/auth/login';
import Home from '../pages/home';

import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import Productsview from '../pages/products/overview';
import Productadd from '../pages/stores/products/page/add';
import Terms from '../pages/terms';


const Layout = () => {
    const theme = useSelector(state => state.authTheme);

    const auth = Cookies.get('token') || theme.userToken;

    const handle_event_click = (event) => {
        const currentTarget = event.currentTarget;
        const closetdiv = event.target.closest('div');
        const allinputs = currentTarget.querySelectorAll('.myInput');
        if (!closetdiv.classList.contains('selected_border')) {
            _removeclass.remove(allinputs, ['selected_border', 'shadow'])
        } else {
            _removeclass.remove(allinputs, ['selected_border', 'shadow']);
            closetdiv.classList.add('selected_border', 'shadow')

        }

    }



    return (
        <div className='layout__wrapper_Link' onClick={handle_event_click}>

            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/products/:id' element={<Products />} />
                    <Route path='/moredetails' element={<Viewpgae />} />
                    <Route path="/option_select" element={<Animatepage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path='/location' element={<Location />} />
                    <Route path='/signup' element={<Signup />} />
                    <Route path='/terms_and_condition' element={<Terms />} />
                    <Route path='/productOverview' element={<ProductOverview />} />
                    <Route path='/products/:id/overview' element={<Productsview />} />
                    <Route path='/userportal' element={<PrivateRoutes auth={auth}>
                        <Userportal />
                    </PrivateRoutes>} />
                    <Route path='/userportal_addproduct' element={<PrivateRoutes auth={auth}>
                        <Productadd />
                    </PrivateRoutes>} />
                    <Route path='/userportal_productoverview' element={<PrivateRoutes auth={auth}>
                        <UserportalOverview />
                    </PrivateRoutes>} />
                </Routes>
            </BrowserRouter>

        </div>
    )
}

export default Layout
