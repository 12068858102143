import React, { useState } from 'react'
import styled from 'styled-components'

const Header = styled.div``;
const MobileSection = styled.div`
height: 60px;
display: none;

@media(max-width: 48rem){
display: flex;
}

`;
const Navbar = ({ saved }) => {
  const [sidebar, setsidebar] = useState('');
  const [showadd, setshowadd] = useState(true);

  return (
    <div>
      <Header className='flex justify-between items-center header_section_top_section_desktop bg-blue-900'>
        <div className="logo flex justify-center font_custome_Linque capitalize text-white font-extrabold items-center">
          Jabuh
        </div>
        <div className="rounded-lg flex items-center justify-between  search_section addproduct_search">

          <div className='ml-3 flex items-center gap-2'>
            <i className='fa-regular fa-triangle-exclamation text-white'></i>

            <div className='text-white'>Unsaved Product</div>
          </div>
          <div className='flex items-center gap-2 mr-3 '>
            <div className='custome__shopproduct rounded-full cursor-pointer text-white'>Discard</div>
            <div className='custome__shopproduct rounded-full cursor-pointer ' style={{ background: "white" }} onClick={saved}>Save</div>
          </div>

        </div>

        <div className=" flex items-center  gap-3 mr-4 user_option_components_avatar">
          <div className=" hover:bg-gray-300 flex cursor-pointer u_highlights rounded-md">
            <div className="icon_u relative p-1 px-2 ">

              <div className="absolute bg-red-600 flex justify-center items-center rounded-full text-white font-medium icon_badge">
                1
              </div>

              <i className='fa-regular fa-bell text-white' style={{ fontSize: 17 }}></i>
            </div>


          </div>

          <div className="avatar cursor-pointer u_highlights  rounded-lg custome_ouliune flex px-1 py-1  items-center gap-2 ">
            <div className="icon__shopdata rounded-lg bg-red-600 flex    justify-center items-center">
              MS</div>
            <span className='text-white font-medium'> My Store</span>
          </div>
        </div>
      </Header>

      <MobileSection className='bg-blue-900 gap-3 items-center menu_dashbaord_mobile'>

        <div onClick={() => { /*setsidebar(!sidebar) */ }} className=' flex items-center justify-center cursor-pointer ml-2 mini___icon rounded-md'>
          <i className='fa-regular fa-bars text-white ' style={{ fontSize: 22 }}></i>
        </div>

        <div className={`${showadd == true ? 'justify-between flex rounded-md search__input___data mobile__navbar_add ' : 'flex rounded-md search__input___data'}`}>



          <div className='ml-3 flex items-center gap-2 unsaved__product'>
            <i className='fa-regular fa-triangle-exclamation text-white'></i>

            <div className='text-white '>Unsaved Product</div>
          </div>
          <div className='flex items-center gap-2 mr-3 btn_groups__'>
            <div className='custome__shopproduct rounded-full cursor-pointer text-white'>Discard</div>
            <div className='custome__shopproduct rounded-full cursor-pointer ' style={{ background: "white" }} onClick={saved}>Save</div>
          </div>




          {/* <div className="flex items-center justify-center icon__md">
            <i className='fa-solid fa-search text-white' style={{ fontSize: 16 }}></i>
          </div>
          <input type="text" className='px-2' style={{ backgroundColor: 'transparent' }} /> */}
        </div>

        <div className='flex'>
          <div className="  icon__bell_mobile  mr-2 flex  justify-center items-center rounded-md">
            <i className='fa-regular fa-bell text-white' style={{ fontSize: 20 }}></i>
          </div>

          <div className="icon__bell_mobile bg-red-600  mr-2 flex  justify-center items-center rounded-md">
            MS
          </div>
        </div>

        {sidebar && <div className="fixed sidebar__in shadow"></div>}

      </MobileSection >

    </div >
  )
}

export default Navbar
