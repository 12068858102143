import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Navbar from '../../components/addproducts_helper/Navbar';
import '@shopify/polaris/build/esm/styles.css';
import ReactQuill from 'react-quill';
import Mainimage from './selectimage.jsx';
import 'react-quill/dist/quill.snow.css';
import { TextField, Select, Image, Page, Card, TextContainer } from '@shopify/polaris';
import axios from 'axios';

const Header = styled.div``;
const FormMobile = styled.div`
@media(min-width: 48rem){
 width: 40%;
 
}
`;

const Productadd = () => {
    const [description, setDescription] = useState('');
    const [category, setcategory] = useState([]);
    const [subcategory, setsubcategory] = useState([]);
    const [title, settitle] = useState('');
    const [price, setprice] = useState('');



    //select categories

    const [option1, setoption1] = useState('');
    const [option2, setoption2] = useState('');
    const [c1, setc1] = useState([]);
    const [c2, setc2] = useState([]);

    useEffect(() => {
        axios.get('productcategory/index').then((res) => {
            localStorage.setItem('category', JSON.stringify(res.data.data));
            console.log(res.data.data);
            setcategory(res.data.data);
            const results = res.data.data.map((cat) => ({
                label: cat.name,
                value: cat.id.toString(),
            }));
            setc1(results);
        });

    }, []);

    // event change
    function handleCategoryChange(event) {
        console.log(event)
        setoption1(event);
        const filtered = category.filter((cat) => cat.id == event);
        const results = Number(event) != -1 && filtered[0].subcategories.map((cat) => ({
            label: cat.name,
            value: cat.id.toString(),
        }))
        setc2(results);
    }

    function handlesubCategoryChange(event) {
        setoption2(event);
    }


    const handleChange = (value) => {

        const formattedValue = value.replace(/[^0-9.]/g, '');
        setprice(Number(formattedValue).toLocaleString());
    };

    return (
        <div className='flex flex-col h-screen bg-gray-200'>
            <div>
                <Navbar />
                <div className='bg-white mx-auto product_header_Page'>
                    <div className='flex p-3 gap-3 items-center'>
                        <div className="bg-white  flex  hover:bg-gray-200 hover:shadow justify-center items-center rounded wrapper__arrow_control">
                            <i className='fa-regular fa-arrow-left'></i>
                        </div>
                        Add Product
                    </div>
                </div>
            </div>
            <div className=' flex-1 flex flex-col  my__product__container'>

                <FormMobile className='mx-auto mobile_forms' >
                    <div className='group_one pt-4  bg-white mt-4'>
                        <div className="form_control_simon mx-auto">
                            <TextField label={<span style={{ fontWeight: '500', fontSize: '14px' }}>Product Title</span>}

                                id="title" value={title} onChange={(value) => settitle(value)} />
                        </div>

                        <div className="form_control_simon  mt-3 mx-auto">
                            <TextField
                                className='custom_placeholder_module'
                                label="Description"
                                value={description}
                                onChange={(event) => setDescription(event)}
                                multiline={4}  
                                placeholder="Enter your product description here..."
                            />
                        </div>
                        <div className='mt-4 mx-auto form_control_simon'>
                            <Mainimage />
                        </div>

                        <div className='pb-4'>
                            <div className="form_control_simon mt-3 mx-auto">
                                <Select label="Category" onChange={handleCategoryChange} options={[{ label: 'Select', value: -1 }, ...c1]} value={option1} />
                            </div>
                            <div className="form_control_simon mt-3 mx-auto">
                                <Select label="Sub Category" options={c2} value={option2}></Select>
                            </div>
                        </div>







                    </div>

                    <div className='group_one pb-5 pt-1  bg-white mt-4'>

                        <div className="form_control_simon mt-3 mx-auto">
                            <Select
                                options={[
                                    { label: "Active", value: 1 },
                                    { label: "Draft", value: 2 },
                                ]}
                                label={<span style={{ fontWeight: '600' }}>Status</span>} >

                            </Select>
                        </div>

                    </div>

                    <div className='group_one pb-5 pt-1  bg-white mt-4'>

                        <div className="form_control_simon mt-3 mx-auto">
                            <TextField label={'Price'}
                                inputMode='decimal'

                                prefix="XAF"
                                placeholder='0.00'
                                id="price" type='currency' value={price} onChange={handleChange} />
                        </div>

                    </div>

                </FormMobile>



            </div>
            <style>
                {`
           .Polaris-Popover {
            border: 1px solid #1a73e8 !important;
            border-radius: 20px !important;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
            
          }

          /* Style dropdown items */
          .Polaris-OptionList__Option {
            font-size: 16px !important;
            padding: 12px 16px !important;
            color: #1a1a1a !important;
          }

          .Polaris-OptionList__Option--selected {
            background-color: #f4f6f8 !important;
            color: #1a73e8 !important;
            font-weight: bold !important;
          }
        `}
            </style>
        </div>

    )


}

export default Productadd
