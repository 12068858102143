import Index from "../../../layout/components/Navigation";
import { Container } from "../../../layout/components/styles";
import img1 from '../../../images/demo/image1.png';
import img2 from '../../../images/demo/image2.png';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import { useState } from "react";
import Footer from "../../../layout/components/footer/footer";
import ProductsCatalog from "../../../layout/components/products/productsCatalog";

const Productsview = () => {

    const [activeimage, setactiveimage] = useState(0);
    const [liking, setliking] = useState('fa-regular');
    // function for large scale screens
    const images = [{ key: 1, img: img1 }, { key: 2, img: img2 },
    { key: 2, img: img1 },

    { key: 5, img: img1 }];


    const increase = (key) => {
        setactiveimage(key + 1)

    }
    const decrease = (key) => {
        setactiveimage(key - 1)
    }

    const Imagegallery = () => {

        function clicking_image(key) {
            console.log(key);
            setactiveimage(key);
        }

        return images.map((image, index) => {
            return <div
                onClick={() => clicking_image(index)}
                className={`gallery mt-3 bg-gray-300 ${index == activeimage && 'active__panel_cart'}  bg-gray-100 flex justify-center items-center border border:black rounded-lg cursor-pointer`}>
                <img src={image.img} className=" rounded " />
            </div>

        })
    }


    // function liking 
    const like = () => {
        if (liking == "fa-regular") {
            setliking('fa-solid text-red-500')
            return
        }

        setliking('fa-regular')
    }



    return <div className="product__overview_page">
        <Index />
        <Container style={{ width: '65%' }} >
            <div className="products__wrapper_overview large_screen_product_">
                <div className="flex relative justify-center mt-10 gap-20 section__one">
                    <div className="first_panel">
                        <p className="font-medium product_large_name product_name">Erliao Popular Pure color 20 pcs Portable Cigarette Case High -valued Reusable Waterproof Metal Cigarette Case</p>
                        <div className="mt-3">No review yet.117</div>
                        <div className="product_category__grop">Bike & Transport  </div>
                        <div className=" flex gap-5 mt-5  image_gallery_overview">
                            <div className="sub_image_cart_gallery">
                                <Imagegallery />

                            </div>


                            <div className="bg-gray-100 relative justify-center flex  rounded-lg gallery__view">
                                <img src={images[activeimage].img} />

                                <div className="absolute shadow rounded-full cursor-pointer flex items-center justify-center bg-white like_icons" onClick={like}>
                                    <i className={`${liking} fa-heart`} style={{ fontSize: 18 }}></i>
                                </div>

                                <div className="flex items-center justify-between absolute controls">
                                    {activeimage > 0 ? <div onClick={() => decrease(activeimage)} className=" border flex cursor-pointer hover:bg-gray-100 items-center justify-center rounded-full  bg-white left_btn">
                                        <i className="fa-regular fa-chevron-left"></i>
                                    </div> : <div></div>}

                                    {activeimage >= images.length - 1 ? <div></div> : <div onClick={() => increase(activeimage)} className=" border flex cursor-pointer items-center hover:bg-gray-100 justify-center rounded-full bg-white  right_btn">
                                        <i className="fa-regular fa-chevron-right"></i>
                                    </div>}
                                </div>
                            </div>



                        </div>

                        <div className="group_table_description mt-5">
                            <div className="font-bold heading_medium key__attributes ">Key attributes
                            </div>
                            <div className="font-medium">Other Attributes</div>

                            <div className="box__control border ">
                                <div className=" col border-gray-400 flex   place__of_origin">
                                    <div className="place bg-gray-100 py-2 px-2">Place of origin</div>
                                    <div className="origin bg-white py-2 px-2">Cameroon </div>
                                </div>
                                <div className=" col border-gray-400 b flex border__botom  place__of_origin">
                                    <div className="place bg-gray-100 py-2 px-2">Brand</div>
                                    <div className="origin bg-white py-2 px-2">s20 altra</div>
                                </div>
                                <div className=" col border-gray-400 b flex border__botom   place__of_origin">
                                    <div className="place bg-gray-100 py-2 px-2">Feature</div>
                                    <div className="origin bg-white py-2 px-2 description__features">
                                        Dual SIM Card, Qwerty Keyboard, Waterproof , Quick Charge, Shockproof, Gaming, Beauty Camera, fingerprint, Face Recognition, WIRELESS CHARGE
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="bg-white p-5  rounded shadow second_panel">
                        <div className="pb-4 quantity__panel">
                            <div>Order Quantity: 1 piece</div>
                            <div className="font-bold heading_medium">CFA 80.00</div>
                            <div className="spacing"></div>
                        </div>

                        <div className="mt-4 pb-5 bottom___variation">
                            <div className="font-bold heading_medium">Variations</div>
                            <div>
                                total option: 4 color; 1RAM; 1 Storage
                            </div>
                            <div className="mt-5">
                                <div className="ramble_">
                                    <div><span className="font-medium inline-block">RAM(1):</span> 1TB</div>
                                    <div className="flex rounded-lg mt-2 items-center justify-center ramble_box">
                                        16g
                                    </div>
                                </div>
                                <div className="mt-4 ramble_">
                                    <div><span className="font-medium inline-block">Storage Capacity(1):</span> 1TB</div>
                                    <div className="flex rounded-lg mt-2 items-center justify-center ramble_box">
                                        16g
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shipping_group pt-4">
                            <div className="font-bold">Shipping</div>
                            <p>Shipping solutions for the selected quantity are currently unavailable</p>
                            <div className="flex mt-5 items-center gap-3">
                                <button className="bg-blue-700 text-white btn__checking" style={{ border: "none" }}>Start Order Request</button>
                                <button className="btn__checking">Send inquiry</button>
                                <buttton className="message rounded-full flex items-center justify-center">
                                    <i className="fa-regular fa-comment-alt"></i>
                                </buttton>
                            </div>
                        </div>
                        <div className="py-5 protection ">

                            <div className="font-bold heading_medium">Intellectual Property Protections</div>

                            <div className="inline__ mt-4">
                                <div className="flex  gap-2">
                                    <div className="icon__l">
                                        <i class="fa-regular fa-shield-alt"></i>
                                    </div>
                                    <div>
                                        <div className="font-semibold para__gram">Secure payments</div>
                                        <div className="mt-2 ">
                                            Every payment you make on Jahuh.com is fully secured, utilizing advanced SSL encryption to protect your sensitive data. Additionally, our compliance with PCI DSS protocols ensures the highest level of security for your transactions, safeguarding your information at every step.
                                        </div>

                                    </div>
                                </div>

                                <div className="flex mt-4 gap-2">
                                    <div className="icon__l">
                                        <PaymentIcon ></PaymentIcon>
                                    </div>
                                    <div>
                                        <div className="font-semibold para__gram">Secure payments</div>
                                        <div className="mt-2 ">
                                            Claim a refund if your order is missing or arrives with product issues. Additionally, enjoy free local returns for defects on qualifying purchases, ensuring a hassle-free experience and peace of mind with your shopping.
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="mt-2">
                    <div className="font-bold product_catalog_heading">Similar Product</div>
                    <div className="flex mt-4 gap-3">
                        <ProductsCatalog img1={img1} />
                        <ProductsCatalog img1={img2} />
                        <ProductsCatalog img1={img1} />
                    </div>
                </div>
            </div>
            <div className="product_overview_mobile_section">
                <div className="p-2 flex items-center ">
                    <div className="pr-2">From</div> <i className="fa-regular fa-chevron-right"></i>
                    <div className="px-2 font-bold">To</div>
                </div>
                <div className="mx-auto mt-2 sec_one">
                    <div className="shadow relative  rounded bg-gray-200 image_">
                        <img src={img1} />
                        <div className="absolute flex items-center justify-center bg-white shadow rounded-full icon_like" onClick={like}>
                            <i className={`${liking} fa-heart`} style={{ fontSize: 17 }}></i>
                        </div>
                    </div>
                    <div className="bg-gray-200 mt-3 font-bold p-2 inline-block rounded price_container_list">
                        FCFA 12,000
                    </div>
                    <div className="mt-2">
                        <div className="p-1 px-2 rounded inline-block text-blue-500 font-medium bg-blue-200 discount_mobile">
                            <i className="fa-regular "></i> FCFA 3,012 OFF
                        </div>
                    </div>
                    <div className="mt-2 mobile_name_and_review">
                        <p className="font__review">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et, est!</p>
                        <div className="flex gap-1">
                            <i className="fa-solid text-gray-400 fa-star"></i>
                            <i className="fa-solid text-gray-400 fa-star"></i>
                            <i className="fa-solid text-gray-400 fa-star"></i>
                            <i className="fa-solid text-gray-400 fa-star"></i>
                            <i className="fa-solid text-gray-400 fa-star"></i>
                        </div>
                    </div>
                    <div className="mt-3 horizontal_scrol flex gap-2 ">
                        <div className=" bg-gray-300 flex items-center justify-center  rounded-lg mobile_cart_control_galler">
                            <img src={img1} alt="" className="rounded" />
                        </div>
                        <div className=" bg-gray-300 flex items-center justify-center  rounded-lg mobile_cart_control_galler">
                            <img src={img1} alt="" className="rounded" />
                        </div>
                        <div className=" bg-gray-300 flex items-center justify-center  rounded-lg mobile_cart_control_galler">
                            <img src={img1} alt="" className="rounded" />
                        </div>
                        <div className=" bg-gray-300 flex items-center justify-center  rounded-lg mobile_cart_control_galler">
                            <img src={img1} alt="" className="rounded" />
                        </div>
                    </div>

                    <div className="fixed p-2 border gap-2 flex items-center border-gray-200 bg-white shadow button__navigation">
                        <div className="flex items-center justify-center icon_store">
                            <i className="fa-regular fa-store"></i>
                        </div>
                        <button className="border flex justify-center rounded-full   chat_now_color items-center ">Chat Now</button>
                        <button className="border flex justify-center rounded-full items-center text-white ordernow ">Order Now</button>
                    </div>
                </div>
                <div className="mt-4 pt-3 bg-gray-100">
                    <div className="bg-white p-2 ">
                        <div className="flex items-center gap-2">
                            <div className="icon_">
                                <i className="fa-regular fa-bank"></i>
                            </div>
                            <div className="font-meduim">Secure payments</div>
                        </div>
                        <div className="mt-2">
                            Every payment you make on Jahuh.com is fully secured, utilizing advanced SSL encryption to protect your sensitive data. Additionally, our compliance with PCI DSS protocols ensures the highest level of security for your transactions, safeguarding your information at every step.
                        </div>

                    </div>
                    <div className="bg-white  p-2 ">
                        <div className="flex items-center gap-2">
                            <div className="icon_">
                                <PaymentIcon></PaymentIcon>

                            </div>
                            <div className="font-meduim">Stand Refund Policy</div>
                        </div>
                        <div className="mt-2">
                            Claim a refund if your order is missing or arrives with product issues. Additionally, enjoy free local returns for defects on qualifying purchases, ensuring a hassle-free experience and peace of mind with your shopping.
                        </div>

                    </div>
                </div>
                <div className="mt-4 pt-4 bg-gray-100">
                    <div className="bg-white p-2 pt-10 ">
                        <div className="font-bold product_catalog_heading">Similar Product</div>
                        <div className="flex  flex-wrap mt-4 gap-3">
                            <ProductsCatalog img1={img1} />
                            <ProductsCatalog img1={img2} />
                            <ProductsCatalog img1={img1} />
                            <ProductsCatalog img1={img1} />
                            <ProductsCatalog img1={img1} />
                        </div>

                    </div>

                </div>
            </div>
        </Container>
        <Footer />

    </div>
}

export default Productsview;

